@import '../../assets/style/main';
.golf-header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 269px;
  flex: 0 0 269px;
  @include backgroundImage(cover);

  @include breakpoint(768px) {
    height: 304px;
    flex: 0 0 304px;
  }

  @include breakpoint(1024px) {
    height: 344px;
    flex: 0 0 344px;
  }

  &.is_preview {
    @include breakpoint(1024px) {
      height: 280px;
      flex: 0 0 280px;
    }
    nav {
      display: none;
    }
  }

  &.is-homepage {
    background: radial-gradient(64.38% 64.38% at 50% 35.63%, rgba(4, 66, 66, 0) 0%, rgba(4, 66, 66, 0.2) 100%),
      url('../../assets/images/header-homepage-v2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @include breakpoint-reverse(767px) {
      background: linear-gradient(180deg, rgba(4, 66, 66, 0) 0%, rgba(4, 66, 66, 0.4) 59.37%),
        url('../../assets/images/header-homepage-v2.jpg');
      background-size: cover;
      background-position: center center;
    }

    &.is-new-banner {
      background: none;
    }

    .golf-header__navigation--sticky-wrapper {
      &>.golf-container {
        max-width: 100%;
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    .golf-header__notification-btn {
      &:hover {
        @media (any-hover: hover) {
          .golf-icon.bell {
            color: $ultra_dark_grey;
          }
        }
      }
      .golf-icon.bell {
        &.active {
          color: $ultra_dark_grey;
        }
      }
    }

    .is-sticky {
      .golf-header__notification-btn {
        &:hover {
          @media (any-hover: hover) {
            .golf-icon.bell {
              color: $ultra_dark_grey;
            }
          }
        }
        .golf-icon.bell {
          &.active {
            color: $ultra_dark_grey;
          }
        }
      }
    }

    h1 {
      @include breakpoint-reverse(767px) {
        margin-bottom: 24px;
      }
    }
  }

  &.is-tall {
    height: 580px;
    flex: 0 0 580px;
    background-position-x: -550px;
    max-height: 100vh;

    @include breakpoint(420px) {
      background-position-x: center;
    }

    @include breakpoint(768px) {
      height: 1024px;
      flex: 0 0 1024px;
    }

    @include breakpoint(1024px) {
      height: 100vh;
      flex: 0 0 100vh;
    }
  }

  .set-max-width-images {
    width: 100%;
  }

  .landing-carousel {
    max-width: 100%;
  }

  .fix-carousel-width {
    max-width: inherit;
  }

  .not-full-height {
    height: 80vh !important;
  }

  &.reduce-height {
    flex: 0 0 80vh !important;
  }

  &.is-full-height {
    background-position-x: -550px;
    height: 100vh;
    flex: 0 0 100vh;

    @include breakpoint(420px) {
      background-position-x: center;
    }

    .golf-header__navigation {
      z-index: 10;
    }

    .golf-header__navigation.is-not-sticky {
      .golf-header__navigation--sticky-wrapper {
        box-shadow: none;
      }
    }
  }

  &.has-nav-only {
    height: auto;
    flex: 0 0 auto;
    z-index: 11;
  }

  &.has-shadow nav {
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
  }

  h1 {
    margin-top: auto;
    color: $white;
    max-width: 510px;

    @include breakpoint(1680px) {
      max-width: 620px;
    }
  }

  &__navigation {
    width: 100%;
    display: flex;
    background-color: $white;

    &.is-sticky {
      height: 64px;
      flex: 0 0 64px;

      @include breakpoint(768px) {
        height: 80px;
        flex: 0 0 80px;
      }
    }

    .golf-custom_header_logo_website_url {
      margin-left: calc(50% - 50px);
      margin-right: auto;

      @include breakpoint(480px) {
        margin-left: auto;
        margin-right: calc(50% - 85px);

        &.user-logged-in {
          margin-right: calc(50% - 79px);

          &.is-admin {
            margin-left: calc(50% - 144px);
            margin-right: auto;

            .golf-custom_header_logo {
              transform: translateX(-50%);
              float: none;
            }
          }
        }
      }

      @include breakpoint(768px) {
        margin-left: calc(50% - 184px);
        margin-right: auto;

        &.user-logged-in {
          margin-left: calc(50% - 184px);
          margin-right: auto;

          &.is-admin {
            margin-left: calc(50% - 184px);
            margin-right: auto;
          }
        }
      }
      
      .golf-custom_header_logo {
        min-width: 0;
        max-width: 180%;
        height: 56px;
        transform: translateX(-50%);
        object-fit: contain;
        float: none;

        @include breakpoint(480px) {
          transform: translateX(50%);
          float: right;
        }

        @include breakpoint(768px) {
          transform: translateX(-50%);
          float: none;
        }
      }
    }

    &--sticky-wrapper {
      box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);

      &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        animation: slide-down 0.3s;
        background-color: $white;
        z-index: 9999;
      }

      &.stay-transparent-on-sticky {
        box-shadow: none;
        background-color: transparent;
      }
    }

    &.is-transparent {
      background: transparent;

      .golf-header__nav-menu-btn {
        span,
        span:before,
        span:after {
          background-color: transparent;
        }

        &.is-active {
          span:before,
          span:after {
            background-color: $ultra_dark_grey;
          }
        }
      }

      .golf-header__link {
        @include breakpoint(1280px) {
          color: $white;
          // opacity: 0.6;
          transition: all 280ms cubic-bezier(0.25, 0.1, 0.25, 1);
          &:hover,
          &:active,
          &.is-active {
            opacity: 1;
            span {
              // border: none;
            }
          }

          .golf-header__link--username {
            color: $white;
          }

          &.has-border span:after {
            background-color: $white;
          }
        }
      }
    }
  }

  &__logo {
    @include backgroundImage(contain);
    background-image: url('../../assets/images/logo-ggs.png');
    width: 164px;
    min-width: 164px;
    height: 41px;

    @include breakpoint(768px) {
      height: 44px;
    }

    &.is-white {
      background-image: url('../../assets/images/logo-ggs.png');
    }
  }

  &__logo-powered-by-gg {
    @include backgroundImage(contain);
    background-image: url('../../assets/images/GG-small.svg');
    width: 50px;
    min-width: 50px;
    height: 41px;

    @include breakpoint(480px) {
      background-image: url('../../assets/images/GG-medium.svg');
      width: 144px;
      min-width: 144px;
      height: 41px;
    }

    @include breakpoint(768px) {
      background-image: url('../../assets/images/powered-by-GG.svg');
      width: 184px;
      min-width: 184px;
      height: 44px;
    }

    &.is-black {
      background-image: url('../../assets/images/GG-small-black.svg');

      @include breakpoint(480px) {
        background-image: url('../../assets/images/GG-medium-black.svg');
      }

      @include breakpoint(768px) {
        background-image: url('../../assets/images/powered-by-GG-black.svg');
      }
    }
  }

  &__nav-menu-btn {
    background-color: transparent;
    border: 0 solid transparent;
    padding: 0;
    cursor: pointer;
    position: relative;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    z-index: 999;

    @include backgroundImage(cover);
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    background-image: url('../../assets/images/icons/user-placeholder-light-grey.svg');
    border-radius: 50%;

    &:focus {
      outline: none;
    }

    @include breakpoint(1280px) {
      display: none;
    }

    span {
      @include breakpoint() {
        position: relative;
        width: 18px;
        height: 2px;
        margin: 4px 0;
        display: block;
        background-color: transparent;
        @include transition(all 0.3s);
      }

      &:before,
      &:after {
        @include breakpoint() {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          border-radius: 2px;
          height: 2px;
          display: inline-block;
          background-color: transparent;
          @include transition(none);
        }
      }

      &:before {
        @include breakpoint() {
          width: 8px;
          transform: translateY(6px);
        }

        @include breakpoint(768px) {
          transform: translateY(5px);
        }
      }

      &:after {
        @include breakpoint() {
          width: 28px;
          transform: translateY(-6px);
        }

        @include breakpoint(768px) {
          transform: translateY(-5px);
        }
      }
    }

    &.is-active {
      background-image: none !important;
      span {
        background-color: transparent;

        &:before,
        &:after {
          width: 100%;
          background-color: $ultra_dark_grey;
          @include transition(all 0.25s);
        }

        &:before {
          transform: translateY(0) rotate(45deg);
        }

        &:after {
          transform: translateY(0) rotate(-45deg);
        }
      }
    }
  }

  &__links-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: $white;
    // box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
    // border-radius: 0px 0px 16px 16px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    z-index: 99;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);

    &.is-active {
      @include breakpoint-reverse(1279px) {
        height: 100vh;
        max-height: 100vh;
      }
    }

    @include breakpoint(1280px) {
      height: auto;
      max-height: 100%;
      box-shadow: none;
      overflow: visible;
      border-radius: 0;
      flex-direction: row;
      position: relative;
      background: transparent;
      width: auto;
    }

    li {
      &:first-child {
        @include breakpoint-reverse(1279px) {
          padding-top: 166px;
        }
      }
      &.golf-header__list-item--homepage {
        padding-top: 66px;
        @include breakpoint(1280px) {
          padding-top: 0;
          display: none;
        }
      }

      &.golf-header__list-item--user {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-bottom: 30px;
        margin-top: 40px;
        z-index: 9999;

        &:hover:after {
          content: '';
          width: calc(100% + 15px);
          height: 90px;
          position: absolute;
          top: 10px;
          left: -15px;
          z-index: 0;
          cursor: pointer;
        }

        &:hover,
        &:active,
        &.is-active {
          .golf-header__link--username {
            @include breakpoint(1280px) {
              color: $ultra_dark_grey;
              border-bottom: 2px solid transparent;
            }
          }
        }
        &:hover {
          @media (any-hover: hover) {
            .golf-dropdown__profile {
              max-height: 500px;
              overflow: visible;
            }
          }
        }

        & + .golf-header__list-item--homepage {
          padding-top: 0;
        }

        &:before {
          // content: "";
          // height: 2px;
          // width: 100%;
          // background-color: $ultra_light_grey;
          // position: absolute;
          // right: 0;
          // top: 100%;
        }

        @include breakpoint(1280px) {
          margin-bottom: 0;
          margin-top: 0;
          order: 9999;

          &:before {
            display: none;
          }
        }
      }

      &.golf-header__list-item--notification {
        display: flex;
        align-items: center;
        order: 9998;
        padding: 0 4px;
        cursor: pointer;
        position: relative;

        @include breakpoint-reverse(1279px) {
          display: none;
        }
      }
    }
  }

  &__link {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    padding: 10px 0;
    width: auto;
    text-align: center;
    transition: all 280ms cubic-bezier(0.25, 0.1, 0.25, 1);

    &:hover,
    &:active,
    &.is-active {
      color: $ultra_dark_grey;
    }

    &.is-admin-events-link {
      @include breakpoint-reverse(1279px) {
        width: auto;
        margin-right: 16px;
      }
    }

    span {
      @include breakpoint(1280px) {
        padding-bottom: 28px;
        border-bottom: 2px solid transparent;
        display: inline-block;

        @media screen and (max-height: 680px) {
          padding-bottom: 18px;
        }
      }

      &.golf-header__link--figure {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        display: block;
        padding: 0;
        border-bottom: none;
        @include backgroundImage(cover);

        @include breakpoint(1280px) {
          width: 40px;
          height: 40px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 12px;
        }
      }

      &.golf-header__link--username {
        text-transform: capitalize;
        font-weight: 400;
        line-height: 32px;

        @include breakpoint(1280px) {
          margin-right: 56px;
          font-weight: 800;
          line-height: 20px;
        }
      }
    }

    @include breakpoint(1280px) {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      width: auto;
      text-align: left;
      padding: 30px 12px 0;
      text-transform: uppercase;
      &:hover,
      &:active,
      &.is-active {
        color: $ultra_dark_grey;
        span {
          border-bottom: 2px solid $orange_700;
          &.golf-header__link--figure {
            border: none;
          }
        }
      }

      @media screen and (max-height: 680px) {
        padding-top: 20px;
      }
    }

    &.has-border {
      span {
        width: 100%;
        position: relative;
        display: inline-block;
      }

      @include breakpoint(1280px) {
        span {
          width: auto;
          padding-bottom: 28px;
          // border-bottom: 2px solid transparent;
          margin-right: 24px;
          display: inline-block;
          margin-bottom: 0;

          &:after {
            content: '';
            height: 20px;
            width: 1px;
            background-color: $light_grey;
            position: absolute;
            right: -24px;
            top: 0;
          }

          @media screen and (max-height: 680px) {
            padding-bottom: 18px;
          }
        }

        &:hover,
        &:active {
          span {
            border-color: $orange_700;
          }
        }
      }
    }

    &.has-mobile-border {
      @include breakpoint-reverse(1279px) {
        position: relative;
        margin-top: 32px;
        padding-top: 40px;
        span {
          &:before {
            content: '';
            height: 2px;
            width: 100%;
            background-color: $ultra_light_grey;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }

    &.is-user {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      z-index: 1;

      @include breakpoint(1280px) {
        flex-direction: row;
        z-index: 99;
      }
    }

    &.is-user-mobile {
      display: flex;
      align-items: center;
      padding-top: 88px;
      padding-bottom: 24px;
      padding-left: 22px;
      padding-right: 22px;

      @include breakpoint(768px) {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }

  &.is-on-nav-mobile {
    @include breakpoint-reverse(1279px) {
      width: auto;
      margin-right: 16px;
      padding-right: 12px;
      padding-left: 12px;
      color: inherit;
      font-weight: 700;
      position: relative;
      text-transform: uppercase;
      font-size: 16px;
    }
    &.is-active:after {
      @include breakpoint-reverse(1279px) {
        content: '';
        height: 2px;
        width: 100%;
        background-color: $orange_700;
        position: absolute;
        right: 0;
        bottom: -18px;
      }
      @include breakpoint-reverse(767px) {
        bottom: -14px;
      }
    }
  }

  &__search {
    display: flex;
    align-items: center;
    margin: 24px 0;

    @include breakpoint-reverse(1023px) {
      flex-wrap: wrap;
    }

    @include breakpoint(768px) {
      margin-bottom: 32px;
    }

    @include breakpoint(1024px) {
      background-color: $white;
      margin: 44px 0 60px;
      border-radius: 16px;
    }
  }

  &__singleImage {
    width: 100vw;
    height: 200px;
    margin-left: -22px;
    @include backgroundImage(cover);

    @include breakpoint(768px) {
      border-radius: 8px;
      width: 100%;
      margin-left: 0;
    }

    @include breakpoint(1024px) {
      height: 240px;
    }
  }

  .golf-dropdown {
    top: 100px;
    right: 0;
  }

  &__notification-btn {
    position: relative;
    height: 100%;
    display: flex !important;
    align-items: center;

    &.sm_desktop\:d-none {
      @include breakpoint(1280px) {
        display: none !important;
      }
    }

    .golf-icon.bell {
      &.active {
        color: $ultra_dark_grey;
        fill: $ultra_dark_grey;
      }
    }

    .is-number {
      position: absolute;
      top: -4px;
      right: 0;
      background-color: $error;
      color: $white;
      line-height: 16px;
      letter-spacing: -0.02em;
      width: auto;
      min-width: 16px;
      padding: 0 2px;

      @include breakpoint(1280px) {
        top: 20px;
        @media screen and (max-height: 680px) {
          top: 12px;
        }
      }
    }

    &:hover {
      @media (any-hover: hover) {
        .golf-icon.bell {
          color: $ultra_dark_grey;
          fill: $ultra_dark_grey;
        }
      }
    }
  }
}

.search__element {
  background-color: $white;
  border-radius: 8px;
  display: flex;
  padding: 6px 16px;
  margin: 5px 0;

  &.mt-0 {
    margin-top: 0;
  }

  &.mb-0 {
    margin-bottom: 0;
  }

  @include breakpoint-reverse(1023px) {
    align-items: center;
  }

  @include breakpoint(1024px) {
    flex-direction: column;
    background-color: transparent;
    margin: 0;
    padding: 5px 32px;
    border-radius: 0;
    align-items: center;
    flex-direction: row;
    border-left: 2px solid $ultra_light_grey;
    &:first-child,
    &:last-child {
      border-left: none;
    }
  }

  &.homepage-event-type {
    @include breakpoint-reverse(1023px) {
      width: calc(50% - 5px);
      margin-right: 5px;
    }
    @include breakpoint-reverse(767px) {
      width: 100%;
      margin-right: 0;
    }
  }

  &.date-range-picker-container {
    @include breakpoint-reverse(1023px) {
      width: calc(50% - 5px);
      margin-left: 5px;
    }
    @include breakpoint-reverse(767px) {
      width: 100%;
      margin-left: 0;
    }
  }

  &.submit-wrapper {
    padding-left: 0;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;

    @include breakpoint-reverse(1023px) {
      padding: 0;
      background-color: $orange_700;
      width: 100%;
      justify-content: center;
    }

    @include breakpoint-reverse(767px) {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    @include breakpoint(1024px) {
      width: 134px;
    }
  }

  label {
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    @include breakpoint(768px) {
      flex: 0 0 auto;
    }

    @include breakpoint(1024px) {
      padding-top: 0;
      padding-bottom: 0;
    }

    @include breakpoint(1280px) {
      padding-right: 24px;
    }
  }
  .search-filter {
    height: 40px;
    @include breakpoint-reverse(767px) {
      width: 100%;
    }
    &__search-wrapper {
      width: 100%;
      @include breakpoint(1280px) {
        width: 300px;
      }
    }
    input {
      border: none;
    }
    .golf-icon {
      display: none;
    }
  }
}

.homepage-datepicker-wrapper {
  @include breakpoint-reverse(1023px) {
    width: calc(50% - 5px);
    margin-left: 5px;
  }
  @include breakpoint-reverse(767px) {
    width: 100%;
    margin-left: 0;

    .DateInput {
      max-width: 84px;
    }

    .DateRangePicker_picker {
      display: none;
    }
  }

  @include breakpoint(1024px) {
    border-left: 2px solid $ultra_light_grey;
  }

  @include breakpoint(1440px) {
    width: 380px;
  }
}

.search__element-date,
.search__element-location,
.search__element-event {
  color: $light_grey;
  font-weight: 800;
  padding-top: 0;
  padding-bottom: 0;

  @include placeholder() {
    color: $light_grey;
    font-size: 16px;
  }

  &:focus {
    @include placeholder() {
      color: $ultra_light_grey;
    }
  }

  @include breakpoint(1024px) {
    padding-left: 14px;
  }
}

.search__element-event,
.search__element-location {
  display: flex;
  flex: 1 1;

  &:focus,
  &:hover {
    outline: none;
  }

  & > div {
    //border: none;
  }

  @include breakpoint(1024px) {
    // min-width: 190px;
  }
}

.search__element-event,
.golf-dropdown {
  padding-left: 0;

  div[class$='-option'] {
    &:active {
      background-color: inherit;
    }

    &:hover {
      @media (any-hover: hover) {
        background-color: $ultra_light_grey;
        transition: all 280ms cubic-bezier(0.25, 0.1, 0.25, 1);
      }
    }
  }
}

.search__element-event {
  div[class$='-menu'] {
    margin-top: 18px;

    @include breakpoint(768px) {
      width: calc(100% + 50px);
    }

    @include breakpoint(1024px) {
      margin-top: 32px;
      margin-bottom: 32px;
      width: 100%;
      min-width: 194px;
    }
  }
}

// Slide Down Animation
@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
