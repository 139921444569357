@import '../../assets/style/main';

.golf-single-event__container {
  &.loading {
    min-height: 100vh;
  }
}

.golf-single-event {
  &__header {
    .golf-header__storySlider .golf-keen-slider--dots {
      margin-bottom: 35px;
      width: 100%;
      max-width: 1300px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 22px;
      padding-right: 22px;

      @include breakpoint(768px) {
        margin-bottom: 55px;
        padding-left: 60px;
        padding-right: 60px;
      }

      @include breakpoint(1024px) {
        margin-bottom: 35px;
        justify-content: flex-start;
      }
    }

    &.golf__one-click-registration,
    &.golf-single-event__registration {
      @include breakpoint-reverse(1023px) {
        display: none;
      }
    }
  }

  &__title {
    position: relative;

    &:after {
      content: '';
      width: calc(100% + 44px);
      height: 40px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      background-color: $white;
      position: absolute;
      top: -40px;
      left: -22px;

      @include breakpoint(768px) {
        width: calc(100% + 80px);
        left: -40px;
      }
    }

    &--tab {
      border: none;
      bottom: 0;
      color: $light_grey;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 20px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 800;

      &.react-tabs__tab--selected {
        color: $ultra_dark_grey;
        position: relative;

        &:after {
          content: '';
          width: 22px;
          height: 2px;
          background-color: $orange_700;
          position: absolute;
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &--tabList {
      border-bottom: 2px solid $ultra_light_grey;
      margin-left: -22px;
      margin-right: -22px;
      padding: 0 30px;
      display: flex;

      @include breakpoint(768px) {
        margin-left: -40px;
        margin-right: -40px;
      }
    }
  }

  &__club-name {
    font-size: 20px;
  }

  &__mobile-tabs {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    position: relative;

    @include breakpoint-reverse(767px) {
      // max-width: 100%;
      overflow-x: hidden;
    }

    &:after {
      content: '';
      width: 100%;
      border-bottom: 2px solid $ultra_light_grey;
      position: absolute;
      bottom: 0;
      left: 0;

      @include breakpoint(768px) {
        width: calc(100% + 60px);
        left: -30px;
      }
    }

    @include breakpoint-reverse(767px) {
      width: calc(100% + 44px);
      overflow-x: auto;
      margin-left: 0;
      margin-right: 0;
      left: -22px;
    }

    button {
      padding: 20px 10px;
      background: none;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      color: $light_grey;
      position: relative;

      @include breakpoint-reverse(767px) {
        white-space: nowrap;

        &:first-child {
          padding-left: 22px;
        }

        &:last-child {
          padding-right: 22px;
        }
      }

      &:after {
        content: '';
        width: 22px;
        height: 2px;
        background-color: $orange_700;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        z-index: 9;
      }

      &::before {
        content: '';
        width: 100%;
        border-bottom: 2px solid $ultra_light_grey;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &.is-active {
        color: $ultra_dark_grey;

        &:after {
          display: block;
        }
      }
    }

    @include breakpoint(1024px) {
      display: none;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    color: $ultra_dark_grey;
    overflow-wrap: break-word;

    blockquote {
      font-style: italic;
    }

    strong {
      font-weight: 800;
    }

    ul {
      list-style: disc;
      padding-left: 20px;
      margin: 16px 0;
    }

    ol {
      list-style: decimal;
      padding-left: 20px;
    }

    a {
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      color: $orange_700;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    table {
      width: 100%;
      overflow: hidden;
      border-spacing: 0;
      border-radius: 16px;
      border: 2px solid $ultra_light_grey;
      max-width: 100%;

      th {
        background-color: $ultra_light_grey;
        font-weight: 800;
        font-size: 16px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: $ultra_dark_grey;
        border: none;
        padding: 0;
      }

      td {
        font-weight: 400;
        font-size: 16px;
        height: 40px;
        text-align: center;
        color: $ultra_dark_grey;
        border: none;
        border-top: 2px solid $ultra_light_grey;

        &:last-child {
          border-left: 2px solid $ultra_light_grey;
        }
      }

      tr:first-child td {
        border-top: 0;
      }
    }

    hr {
      border: none;
      border-top: 1px solid $ultra_light_grey;
      margin: 40px 0;
    }

    img {
      max-width: 100%;
      height: auto !important;
      object-fit: contain;
    }
  }

  &__member-box {
    display: flex;
    flex-direction: column;

    @include breakpoint(1024px) {
      flex-direction: row;
    }

    img {
      flex: 0 0 60px;
      width: 60px;
      height: 60px;
      margin: 0 auto 20px;

      @include breakpoint(768px) {
        margin: 0 0 20px;
      }

      @include breakpoint(1024px) {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }
  }

  &__schedule-box {
    li p:first-child {
      background-color: $ultra_light_grey;
      border-radius: 8px;
      padding: 10px;
      margin-right: 20px;
    }
  }

  &__content-wrapper {
    width: 100%;

    @include breakpoint(1280px) {
      margin-right: 28px;
      width: 65%;
    }
  }

  &__event-box-wrapper {
    // margin-top: 40px;
    justify-content: center;
    align-items: flex-start;

    @include breakpoint-reverse(1023px) {
      top: 0;
    }

    @include breakpoint(1280px) {
      // position: sticky;
      // margin-top: -188px;
      // margin-left: auto;
      // top: 70px;
      margin-top: -64px;
      flex: 0 0 373px;
    }

    &.top-cta {
      @include breakpoint(1024px) {
        // margin-top: -248px;
      }
    }
  }

  &__package-wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: 20px;
    row-gap: 16px;

    @include breakpoint(768px) {
      row-gap: 20px;
    }
  }

  &__sponsors {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    width: 100%;

    @include breakpoint(1024px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @supports not (display: grid) {
      display: flex;
      justify-content: space-between;
    }

    a {
      display: flex;
      align-items: center;
      width: 100%;
    }

    figure {
      width: 100%;
      height: 80px;
      padding: 8px;
      border: 2px solid rgba($ultra_light_grey, 0.8);
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      align-items: center;
      align-self: center;
      justify-content: center;

      @supports not (display: grid) {
        width: calc(25% - 12px);
      }

      img {
        width: 100%;
        max-height: 64px;
        height: auto;
        object-fit: contain;
      }
    }
  }

  &__scorecard--tab {
    margin-right: 20px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $blue_200;
    color: $blue_800;
    opacity: 0.3;

    &:active {
      background: $blue_400;
    }

    &.react-tabs__tab--selected {
      background: $blue_200;
      color: $blue_700;
      opacity: 1;
    }
  }
}

.golf-carousel-slider__arrows-wrapper.golf-single-event__carousel {
  top: -104px;
  right: 0;
  width: 100%;
  position: relative;
  display: flex;
  z-index: 1000;
  pointer-events: auto;
  justify-content: space-between;

  .prev-button {
    border-radius: 0px 20px 0px 0px;
    padding: 50px;
  }
  .next-button {
    border-radius: 20px 0px 0px 0px;
    padding: 50px;
    margin-right: 15px;
  }

  .big-arrow {
    width: 100px !important;
    height: 100px !important;
    flex: 0 0 80px;
  }

  @include breakpoint-reverse(1279px) {
    display: none;
  }
}

.golf-carousel-slider__container {
  @include breakpoint-reverse(1023px) {
    // margin-right: -40px;
    padding-left: 0;
  }

  @include breakpoint-reverse(767px) {
    margin-right: 0;
  }
}

//Reset React Tabs
.react-tabs,
.golf-scorecard__tabs .react-tabs {
  &__tab {
    border: none;
    cursor: pointer;
    color: $light_grey;
    padding-bottom: 8px;
    padding-right: 0;
    padding-left: 0;
    margin-right: 40px;

    &:focus {
      box-shadow: none;
    }

    &--selected {
      color: $ultra_dark_grey;
      position: relative;

      p {
        font-weight: 800;
      }

      &:after {
        content: '';
        width: 35%;
        height: 2px;
        background: $orange_700;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &-panel--selected {
      // @include breakpoint-reverse(1023px) {
      //     width: calc(100% + 40px);
      //     overflow: auto;
      // }
      // @include breakpoint-reverse(767px) {
      //     width: calc(100% + 22px);
      // }
    }
  }

  &__tab-list {
    border: none;
    display: flex;
    margin-bottom: 25px;
  }
}

.tab-show {
  @include breakpoint-reverse(1023px) {
    display: block;
  }
}

.tab-hide {
  @include breakpoint-reverse(1023px) {
    display: none;
  }
}

.golf-orange-underline-tabs {
  .react-tabs {
    &__tab {
      padding-bottom: 0;
      padding-right: 0;
      padding-left: 0;
      margin-right: 34px;

      &--selected {
        border: none;

        &:after {
          width: 100%;
        }
      }
    }

    &__tab-list {
      border: none;
      margin-bottom: 0;
    }
  }
}

.golf-events {
  &__list {
    &-title {
      display: flex;
      margin-bottom: 12px;
      justify-content: space-between;
      display: flex;
    }

    &-wrapper {
      padding-top: 60px;

      @include breakpoint(1024px) {
        padding-left: 60px;
        border-left: 2px solid $ultra_light_grey;
      }

      @media screen and (max-height: 700px) {
        padding-top: 40px;
      }
    }

    &-feed {
      @supports not (display: grid) {
        display: flex;
        flex-direction: column;
      }

      &-no-events {
        display: flex;
        flex-direction: column;

        @include breakpoint-reverse(1279px) {
          grid-column: 1 / 5;
        }

        figure {
          width: 100%;
          max-width: 670px;
          flex-grow: 1;
          min-height: 220px;
          @include backgroundImage(contain);
        }
      }
    }

    &-grid {
      column-gap: 32px;
      row-gap: 32px;
      grid-template-columns: repeat(1, minmax(0, 1fr));

      @include breakpoint(640px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include breakpoint(980px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @include breakpoint(1280px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
  }

  &__search-tablet {
    .search-filter__search-wrapper {
      @include breakpoint-reverse(1279px) {
        min-width: 300px;
      }
    }
  }

  &__search-mobile {
    .search-filter__search {
      background: $ultra_light_grey;
      border-color: $ultra_light_grey;
    }

    .golf-slider-input {
      >div:first-child {
        width: 100% !important;
      }

      .golf-input {
        width: 64px !important;
      }
    }
  }
}

.golf-events__search-wrapper {
  position: relative;
  min-width: 300px;

  &.profile-search {
    width: 100%;

    @include breakpoint(1024px) {
      width: 373px;
    }
  }

  &.transaction-search {
    @include breakpoint(1024px) {
      width: 275px;
    }
  }

  .search,
  .close-icon {
    position: absolute;
    top: 10px;
    right: 16px;
  }

  .search-filter__search-wrapper {
    @include breakpoint-reverse(1023px) {
      width: 100%;

      .bold-pin {
        display: none;
      }
    }
  }
}

.golf-events__number-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include breakpoint-reverse(1023px) {
    justify-content: flex-start;
  }

  .golf-input__collapse {
    max-height: 0;
    overflow: hidden;

    &.is-expanded {
      max-height: 300px;
    }
  }

  &.is-expanded {
    .golf-input__collapse {
      max-height: 300px;
    }
  }

  .golf-input {
    max-width: 75px;
    display: flex;
    align-items: center;
    padding: 8px 7px;

    &.pl-22 {
      padding-left: 22px;
    }

    &__icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 8px;
      font-size: 16px;
      line-height: 24px;
    }

    &__wrapper {
      position: relative;
      display: inline-block;
    }

    + .golf-icon {
      display: none;
    }

    &.is-not-focused {
      background-color: $ultra_light_grey;
      border: 2px solid $ultra_light_grey;
      color: $ultra_dark_grey;
      font-weight: 700;
      position: relative;
      cursor: pointer;

      + .golf-icon {
        position: absolute;
        background-color: $ultra_light_grey;
        top: 10px;
        right: 4px;
        display: block;
        cursor: pointer;
      }

      @include breakpoint-reverse(1023px) {
        background-color: $blue_300;
        border: 2px solid $blue_300;
        color: $blue_700;
        + .golf-icon {
          background-color: $blue_300;
          &:after,
          &:before {
            background-color: $blue_700;
          }
        }
      }
    }

    &.is-disabled {
      + .golf-icon {
        display: none;
      }
    }
  }
}

.golf-event-registration {
  &__steps {
    @include breakpoint-reverse(1023px) {
      display: grid;
      column-gap: 4px;
      margin-bottom: 17px;
      // position: relative;
      // top: -4px;
      // width: calc(100% + 80px);
      // margin-left: -40px;
      // margin-right: -40px;
      position: fixed;
      width: 100%;
      top: 80px;
      left: 0;
      z-index: 11;
    }

    @include breakpoint-reverse(767px) {
      // width: calc(100% + 44px);
      // margin-left: -22px;
      // margin-right: -22px;
      top: 64px;
    }

    span {
      background: $ultra_light_grey;
      height: 4px;

      &.is-active {
        background: $orange_700;
      }
    }
  }

  &__sidebar {
    @include breakpoint(1024px) {
      flex: 0 0 calc(1 / 3 * 100%);
      margin-left: auto;
    }

    @include breakpoint(1280px) {
      flex: 0 0 375px;
    }
  }

  &__list-pagination {
    position: sticky;
    bottom: 0;
    background-color: $white;
    margin-top: 152px;
    border-top: 2px solid $ultra_light_grey;
    display: flex;
    padding: 20px 22px;
    justify-content: space-between;

    @include breakpoint(768px) {
      padding: 20px 40px;
    }

    @include breakpoint(1024px) {
      padding: 20px 64px;
    }
  }

  &__step-wrapper {
    animation: fadeIn ease-in-out 0.3s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.golf-event-registration__packages-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 20px;
  row-gap: 16px;
}

.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.golf-carousel-slider__wrapper.responsive-full--width {
  @include breakpoint-reverse(1279px) {
    width: calc(100% + 80px);
    margin-left: -40px;

    .golf-section--title {
      margin-left: 40px;
    }

    .golf-carousel-slider__container {
      margin-left: 30px;
    }
  }

  @include breakpoint-reverse(767px) {
    width: calc(100% + 44px);
    margin-left: -22px;

    .golf-section--title {
      margin-left: 22px;
    }

    .golf-carousel-slider__container {
      margin-left: 12px;
    }
  }
}

.global-filters-container {
  & > div:not(.golf-modal__filters) {
    input, .primary-responsive-btn, .react-datepicker__input-container > div  {
      border-radius: 0;
      border-color: $white $white $lighter_grey $white;

      @include breakpoint(768px) {
          border-color: $white $white $white $lighter_grey;
      }

      &:focus {
        border-color: $orange_700
      }
    }
  }

  .radio-input-status {
    display: flex;

    .radio-status-label {
      margin-top: 0px;
    }

    .status-input {
      padding: 0px;
      margin-right: 10px;
    }
  }

  .search-location {
    .search-filter__search {
      &:not(:focus) {
          border-left-color: $white;
      }
    }
  }

}
