.golf-card {
  &__link {
    .icon-btn {
      position: absolute;
      top: 104px;
      right: 16px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__link--date {
    position: absolute;
    top: 32px;
    left: 20px;
    background: $white;
    color: $ultra_dark_grey;
    padding: 6px 8px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    border-radius: 8px;
    text-align: center;
    max-width: 40px;
    word-spacing: 100vw;

    @include breakpoint(768px) {
      left: 16px;
      top: 52px;
    }

    @include breakpoint(1024px) {
      padding: 8px 12px;
      line-height: 16px;
      top: 76px;
      max-width: 48px;
    }

    &.is-in-high-demand {
      background: $orange_400;
      color: $white;
    }
  }
}

.scale-card {
  transform: scale(0.8);
}

.golf-card__vertical {
  position: relative;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  width: 265px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  @include breakpoint(1420px) {
    max-width: 100%;
  }

  .golf-card__image {
    height: 150px;
    width: 100%;
    object-fit: cover;
  }

  .golf-card__image--box {
    display: flex;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
  }

  &--title {
    min-height: 56px;
  }

  .golf-card__link--date {
    top: 52px;

    @include breakpoint(768px) {
      padding: 8px 12px;
      line-height: 16px;
      max-width: 48px;
    }

    @include breakpoint(1024px) {
      top: 96px;
    }
  }

  .icon-btn {
    top: 36%;
  }
}

li[aria-hidden='false']+li[aria-hidden='true'] {
  @include breakpoint(1280px) {
    .golf-card__vertical {
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
    }
  }
}

.golf-card__horizontal {
  position: relative;
  flex-direction: column;
  animation: fadeIn ease-in-out 0.3s;

  @include breakpoint(1280px) {
    flex-direction: row;
  }

  @supports not (display: grid) {
    margin-bottom: 20px;
    display: inline-flex;

    @include breakpoint(1024px) {
      margin-bottom: 32px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .golf-recently-viewed & {
      @include breakpoint(1024px) {
        width: calc(50% - 10px);

        &:nth-child(2n + 1) {
          margin-right: 20px;
        }
      }
    }
  }

  .icon-btn:not(.favorite-btn) {
    right: 5%;
    top: 67%;

    @include breakpoint-reverse(480px) {
      top: 60%;
    }

    @include breakpoint(1024px) {
      right: 15px;
      top: 85px;
    }

    @include breakpoint(1280px) {
      right: auto;
      left: 205px;
      top: 95px;
    }
  }

  &--txt-wrapper {
    @include breakpoint-reverse(1279px) {
      width: 100%;
    }

    h4 {
      @include breakpoint-reverse(1279px) {
        font-size: 16px;
        line-height: 18px;
      }
    }

    h4+span {
      @include breakpoint-reverse(1279px) {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
      }
    }

    h4+p {
      @include breakpoint-reverse(1279px) {
        margin-left: auto;
      }
    }

    &>p {
      @include breakpoint-reverse(1279px) {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }

      .golf-icon {
        @include breakpoint-reverse(1279px) {
          width: 16px;
          height: 16px;
          flex: 0 0 16px;
        }
      }
    }

    .caption {
      @include breakpoint-reverse(1279px) {
        flex: 0 0 36px;
        line-height: 20px;
      }
    }

    .charity-label {
      border: 2px solid $blue_300;
      border-radius: 16px;
      color: $blue_700;
      padding: 0 8px;
    }
  }

  &--image-wrapper {
    position: relative;
    width: 100%;
    max-height: 100%;
    padding-bottom: 56.25%; // 16:9 aspect ratio

    @include breakpoint(1024px) {
      height: 130px;
      padding-bottom: 0;
    }

    @include breakpoint(1280px) {
      width: 250px;
      height: 140px;
      padding-bottom: 0;
    }
  }

  .golf-card__image {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .golf-card__link {
    position: absolute;
    height: 100%;

    .golf-card__image--box {
      display: flex;
      border-radius: 16px;
      overflow: hidden;
      height: 100%;

      &.is-in-high-demand {
        border: 2px solid $orange_400;

        &:before {
          content: '';
          width: 32px;
          height: 32px;
          background-color: $orange_400;
          position: absolute;
          top: 0;
          left: 0;
          border-top-left-radius: 16px;
          border-bottom-right-radius: 8px;
          z-index: 1;
        }

        &:after {
          content: '';
          width: 32px;
          height: 32px;
          position: absolute;
          top: 2px;
          left: 2px;
          background-image: url('../../images/icons/fire.svg');
          background-size: 28px 28px;
          z-index: 2;
        }
      }

      &.is-completed {
        opacity: 0.5;
      }
    }
  }
}

.golf-card__profile {
  position: relative;
  
  .icon-btn:not(.favorite-btn) {
    right: auto;
    left: 12px;
    top: 12px;

    @include breakpoint(640px) {
      left: 205px;
      top: 95px;
    }
  }

  &--txt-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
    flex: 1;
    padding-left: 20px;

    h4, span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      vertical-align: middle;
    }

    h4 {
      color: $ultra_dark_grey;
    }
  }

  &--image-wrapper {
    position: relative;
    width: 100px;
    height: 140px;

    @include breakpoint(640px) {
      width: 250px;
      height: 140px;
    }
  }

  .golf-card__image {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .golf-card__link {
    position: absolute;
    height: 100%;
    width: 100%;
    
    .golf-card__image--box {
      display: flex;
      border-radius: 16px;
      overflow: hidden;
      height: 100%;

      &.is-in-high-demand {
        border: 2px solid $orange_400;

        &:before {
          content: '';
          width: 32px;
          height: 32px;
          background-color: $orange_400;
          position: absolute;
          top: 0;
          left: 0;
          border-top-left-radius: 16px;
          border-bottom-right-radius: 8px;
          z-index: 1;
        }

        &:after {
          content: '';
          width: 32px;
          height: 32px;
          position: absolute;
          top: 2px;
          left: 2px;
          background-image: url('../../images/icons/fire.svg');
          background-size: 28px 28px;
          z-index: 2;
        }
      }
    }
  }
}

.golf-card__tile-view {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 172px;
  animation: fadeIn ease-in-out 0.3s;
  padding: 16px;
  border: 1px solid $lighter_grey;
  border-radius: 8px;

  @media (min-width: 640px) and (max-height: 500px) {
    width: 300px !important;
    height: 132px !important;
  }

  &.map-mobile-tile {
    height: 132px !important;

    .golf-card__tile-view--txt-wrapper {
      margin-right: 24px !important;
    }

    .golf-card__link {
      height: 103px;
    }
  }

  .tile-fit {
    flex-direction: row;

    @media (min-width: 640px) and (max-height: 500px) {
      flex-direction: row !important;
    }

    @include breakpoint(640px) {
      flex-direction: column;
    }
  }

  @include breakpoint(640px) {
    width: 300px;
    height: 320px;
  }

  &:hover {
    border: 1px solid $orange_700;
    border-radius: 8px;
  }

  .icon-btn:not(.favorite-btn) {
    top: 8px;
    left: 8px;

    @include breakpoint(640px) {
      left: auto;
      right: 8px;
    }

    border-radius: 8px;
  }

  &--txt-wrapper {
    width: 100%;
    overflow: hidden;
    margin: 0 0 0 12px;

    @media (min-width: 640px) and (max-height: 500px) {
      margin: 0 !important;
    }

    @include breakpoint(640px) {
      margin: 8px 0 0 0;
    }

    h4 {
      width: 100%;
      height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $ultra_dark_grey;
    }

    span {
      height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &--image-wrapper {
    position: relative;
    width: fit-content;
    max-height: 100%;

    @media (min-width: 640px) and (max-height: 500px) {
      padding: 0px !important;
      width: auto !important;
    }

    @include breakpoint(640px) {
      width: 100%;
      padding-bottom: 56.25%; // 16:9 aspect ratio
    }
  }

  .golf-card__image {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .golf-card__link {
    position: relative;
    height: 100%;
    width: 100px;
    height: 140px;

    @media (min-width: 640px) and (max-height: 500px) {
      width: 100px !important;
      height: 103px !important;
      position: relative !important;
      margin-right: 12px !important;
    }

    @include breakpoint(640px) {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .golf-card__image--box {
      display: flex;
      border-radius: 8px;
      overflow: hidden;
      height: 100%;

      &.is-in-high-demand {
        border: 2px solid $orange_400;

        &:before {
          content: '';
          width: 32px;
          height: 32px;
          background-color: $orange_400;
          position: absolute;
          top: 0;
          left: 0;
          border-top-left-radius: 8px;
          border-bottom-right-radius: 8px;
          z-index: 1;
        }

        &:after {
          content: '';
          width: 32px;
          height: 32px;
          position: absolute;
          top: 2px;
          left: 2px;
          background-image: url('../../images/icons/fire.svg');
          background-size: 28px 28px;
          z-index: 2;
        }
      }

      &.is-completed {
        opacity: 0.5;
      }
    }
  }

  button {
    text-align: left;
  }

  .primary-full-w-btn {
    text-transform: capitalize;
  }
}

.golf-card__list-view {
  display: flex;
  background-color: $white;
  padding: 12px 24px;
  cursor: pointer;

  &:hover {
    background-color: $orange_100;
    outline: 1px solid $orange_700;
  }
  
  &--date-container {
    display: flex;
    align-items: center;
  }

  h4 {
    width: 100%;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $ultra_dark_grey;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.golf-card__map {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 280px;
  overflow: hidden;
  font-size: 14px;
  font-family: "Open Sans", "Proxima Nova", -apple-system, Arial, sans-serif;

  .golf-card__image {
    height: 175px;
    width: 280px;
    object-fit: cover;
  }

  .golf-card__image--box {
    display: flex;
    overflow: hidden;
  }

  .golf-card__event--title {
    height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    color: $ultra_dark_grey;
  }

  .primary-full-w-btn {
    text-transform: capitalize;
  }
}

.golf-card__event {
  width: 100%;
  background-color: $white;

  @include breakpoint(1280px) {
    border: 2px solid $ultra_light_grey;
    border-radius: 16px;
    max-width: 373px;
    margin-left: auto;
  }

  &--high-demand {
    display: flex;

    @include breakpoint-reverse(1279px) {
      flex-direction: column;
      align-items: center;
    }

    @include breakpoint-reverse(767px) {
      flex-direction: row;
      align-items: flex-start;
    }

    figure {
      flex: 0 0 60px;
      width: 60px;
      height: 60px;
      background-color: $orange_400;
      position: relative;
      border-radius: 8px;

      &:before {
        content: '';
        width: 60px;
        height: 60px;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('../../images/icons/fire.svg');
        background-size: 60px 60px;
        z-index: 2;
      }
    }
  }

  &--btns-wrapper {
    display: none;

    @include breakpoint-reverse(1023px) {
      flex-wrap: wrap;
    }

    @include breakpoint(768px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &--registration--wrapper {
    @include breakpoint-reverse(1023px) {
      position: fixed;
      width: 100%;
      height: 100%;
      bottom: 0;
      left: 0;
      max-height: 0;
      overflow: hidden;
      z-index: 9;

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.15);
        z-index: -2;
      }

      &.is-active {
        max-height: 100vh;

        .golf-card__event--registration {
          max-height: 80vh;
          overflow-y: auto;
        }
      }
    }

    .golf-card__event--registration {
      @include breakpoint-reverse(1023px) {
        bottom: 0;
        position: absolute;
        width: 100%;
        max-width: 100%;
        left: 50%;
        transform: translateX(-50%);
        padding: 20px calc(50vw - 187px) 20px;
        border-radius: 16px 16px 0px 0px;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
        transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
        max-height: 0;
      }

      @include breakpoint-reverse(767px) {
        padding: 20px 22px 20px;
        border-radius: 16px;
      }
    }
  }

  &--registration {
    @include breakpoint(1024px) {
      flex: 0 0 375px;
    }
  }

  &--expand-container {
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.3s ease-in-out;

    &.is-expanded {
      max-height: 1000px;
      transition: all 0.3s ease-in-out;
    }
  }

  .copy-to-clipboard-button__message {
    right: 50%;
    transform: translateX(50%);
    top: -43px;
  }
}

.golf-card__division {
  background: $blue_200;
  border-radius: 16px;
  padding: 20px;
  border: 2px solid $blue_200;
  position: relative;

  &.is-active {
    background: $white;
    border-color: $blue_600;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);

    .check,
    .users,
    b:last-child {
      display: block;
    }
  }

  &>.check {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%) translateX(50%);
    display: none;
  }

  .users,
  b:last-child {
    display: none;
  }
}

.golf-card__addon {
  @extend .golf-card__division;
  background: $white;
  border: 1px solid $ultra_light_grey;
  padding: 19px 20px;

  &.is-active {
    border-width: 1px;
    box-shadow: none;
  }

  &.register-addon {
    @include breakpoint-reverse(767px) {
      flex-wrap: wrap;
      align-items: flex-start;

      .golf-card__addon-description {
        margin-top: 0;
        min-height: 48px;
        margin-bottom: 11px;
      }
    }
  }

  .counter-number {
    width: 45px;
    text-align: center;
    line-height: 36px;
    display: inline-block;
    border-radius: 8px;
    border: 2px solid $ultra_dark_grey;

    @include breakpoint(768px) {
      width: 41px;
    }
  }

  .golf-icon.is-disabled {
    border-color: $light_grey;
    cursor: disabled;

    &:after,
    &::before {
      background-color: $light_grey;
    }
  }
}

.golf-card__package {
  @extend .golf-card__division;
  background: $orange_100;
  border: 2px solid $orange_100;
  padding: 24px 16px;
  display: flex;
  flex-wrap: wrap;

  @include breakpoint(1280px) {
    border: none;
    align-items: center;
    padding: 20px;
  }

  b,
  b:last-child {
    display: block;
  }

  @supports not (display: grid) {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-title {
    line-height: 20px;
    font-size: 16px;
    margin-bottom: 24px;

    @include breakpoint(1280px) {
      margin-bottom: 0;
    }

    .golf-card__package-title--divisions {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }

  &-tag {
    font-size: 12px;
    line-height: 20px;
    color: $orange_500;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 2px;

    @include breakpoint(1280px) {
      display: none;
    }
  }

  &-price {
    @include breakpoint-reverse(1279px) {
      border-right: 2px solid $orange_300;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      b {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
      }
    }
  }

  &-golfers {
    @include breakpoint-reverse(1279px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  &-availability {
    @include breakpoint-reverse(1279px) {
      border: 2px solid $orange_200;
      border-right: none;
      display: none;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &.caption {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  &-includes {
    @include breakpoint-reverse(1279px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 22px;
      order: 9;
      flex-wrap: wrap;

      span {
        width: 100%;
        text-align: left;
        margin-bottom: 10px;
      }

      li:not(.list-btn-wrapper) {
        background: $orange_200;
        border-radius: 8px;
        min-width: 60px;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 16px;
        margin-bottom: 6px;
        padding: 8px;

        p {
          text-align: center;
          color: $orange_500;

          &:first-child {
            color: $ultra_dark_grey;
            font-size: 20px;
            line-height: 28px;
            font-weight: 800;
          }
        }

        span {
          display: block;
          font-size: 20px;
          line-height: 28px;
          color: $ultra_dark_grey;

          &:before {
            content: 'x';
            display: inline-block;
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
    }
  }

  .golf__link {
    order: 10;

    @include breakpoint-reverse(1279px) {
      width: 100%;
      padding: 24px 0 0;
      justify-content: center;

      &.no-border-top {
        border-top: none;
      }
    }
  }

  &.is-white-package {
    background: $white;
    border: 1px solid $ultra_light_grey;
  }

  .radio-check {
    border: 2px solid $light_grey;
    border-radius: 50%;
    width: 20px;
    flex: 0 0 20px;
    height: 20px;

    &.is-active {
      background-color: $orange_700;
      border-width: 0px;
    }
  }
}

.golf-card__fees {
  &.fixed-mobile-card {
    @include breakpoint-reverse(1023px) {
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      max-height: 0;
      background-color: $blue_200;
      box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
      border-radius: 16px 16px 0px 0px;
      transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

      &.is-active {
        max-height: 100vh;
      }

      .golf-card__fees--list {
        background-color: $white;
        max-width: 335px;
        margin: 0 auto 20px;
      }
    }
  }

  @include breakpoint(1024px) {
    margin-bottom: 40px;
  }

  &--wrapper {
    @include breakpoint-reverse(1023px) {
      position: fixed;
      width: 100%;
      height: 100vh;
      bottom: 0;
      left: 0;
      max-height: 0;
      background-color: rgba(0, 0, 0, 0.15);
      z-index: 9;

      &.is-active {
        max-height: 100vh;

        .golf-card__fees {
          max-height: 100vh;
        }
      }
    }
  }

  &--title {
    @include breakpoint-reverse(1023px) {
      display: flex;
      max-width: 335px;
      margin: 20px auto;
    }
  }

  &--list {
    background-color: $blue_100;
    border-radius: 16px;
    padding: 20px;

    li {
      padding: 10px 0;
      border-bottom: 2px solid $blue_200;

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      &:first-child {
        padding-top: 0;
      }
    }
  }

  &--price {
    padding: 16px 20px;
    border-radius: 16px;
    margin: 0;
    background-color: $blue_700;
    color: $white;
    display: flex;

    &.p-16 {
      padding: 16px;
    }

    @include breakpoint-reverse(1023px) {
      max-width: 335px;
      margin: 0 auto 20px;
    }
  }

  &__stories {
    width: 100%;
    max-width: 375px;
    margin: 60px auto 0;
  }
}

.golf-card__name {
  border: 1px solid $ultra_light_grey;
  border-radius: 16px;
  padding: 9px 20px;
  font-weight: 800;

  &.team-card {
    padding: 20px;
    background-color: $ultra_light_grey;
    border: none;
    font-size: 20px;
    line-height: 28px;
  }

  &--team-members {
    font-size: initial;
    line-height: initial;
    font-weight: initial;
  }
}

.golf-card__payment {
  flex-grow: 1;
  width: auto;
  min-width: 222px;
  border: 2px solid $blue_600;
  border-radius: 8px;
  padding: 14px 16px;
  display: inline-block;
  position: relative;
  color: $blue_600;

  &.is-md {
    min-width: 222px;
    padding: 17px;

    .uppercase {
      text-transform: lowercase;
    }

    .expiry-date {
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
    }

    .items-end {
      align-items: center;
    }
  }

  &--image {
    width: 41px;
    height: 28px;
    display: inline-block;
    @include backgroundImage(contain);

    &.visa {
      background-image: url('../../images/logo-visa.png');
    }

    &.mastercard {
      background-image: url('../../images/logo-mastercard.png');
    }
  }

  &>.check {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%) translateX(50%);
    display: none;
  }

  .btn-remove {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%) translateX(50%);
    background-color: $white;
    border-radius: 50%;

    &.d-none {
      display: none;
    }
  }
}

.golf-card__registration {
  padding: 20px;
  border: 2px solid $ultra_light_grey;
  border-radius: 16px;
  position: relative;

  &.is-unfinished {
    border-color: $orange_300;
  }

  .close-form-btn {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-50%) translateX(50%);
    background-color: $white;
    border-radius: 50%;
  }

  @supports not (display: grid) {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.golf-profile .profile-form-wrapper .golf-card__transaction hr {
  width: 100%;
  left: auto;
}

.golf-card__transaction {
  border: 2px solid $ultra_light_grey;
  padding: 20px;
  border-radius: 16px;
}

.golf-card__flag {
  display: flex;
  padding: 16px;

  &.is-success {
    background-color: $blue_300;
    border: 2px solid $blue_600;
    color: $blue_600;
  }

  &.is-warning {
    background-color: $yellow_warning;
    border: 2px solid $yellow_warning;
    color: $ultra_dark_grey;
  }

  &.is-error {
    background-color: $error_2;
    border: 2px solid $error_2;
    color: $white;
  }

  &__wrapper {
    position: fixed;
    top: 96px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    width: 90%;
    max-width: 570px;
  }
}

.golf-card__club-event {
  border: 1px solid $ultra_light_grey;
  border-radius: 8px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  &__description {
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    line-height: 1.5rem;

    &.trimmed {
      max-height: 6rem;
      -webkit-line-clamp: 4;
    }
  }

  .primary-full-w-btn {
    text-transform: capitalize;

    @include breakpoint(756px) {
      text-align: left;
    }
  }
}